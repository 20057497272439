.pushCarousel .leCarou {
  height: calc(100vh - 136px);
}

.pushCarousel .leBg {
  height: calc(100vh - 136px);
}

.pushCarousel .leBg .innerBg:first-child {
  height: 100%;
  width: 100%;
}

.pushCarousel .leBg .innerBg:not(:first-child) {
  height: 0;
  max-height: inherit;
  width: 100%;
  overflow: hidden;
}

.pushCarousel .leCarou .innerImg:not(:first-child) {
  height: 0;
  max-height: inherit;
  width: 100%;
  overflow: hidden;
}

.pushCarousel .entree:not(:first-child) {
  position: relative;
  top: 100vh;
  left: 0;
}

@media screen and (max-width: 900px) {
  .pushCarousel {
    top: 0 !important;
  }
}

@media screen and (max-height: 870px) {
  .leCarou {
    max-width: 530px !important;
    max-height: 600px !important;
  }
}

@media screen and (max-height: 795px) {
  .leCarou {
    max-width: 500px !important;
    max-height: 550px !important;
  }
}

@media screen and (max-height: 740px) {
  .leCarou {
    max-width: 450px !important;
    max-height: 500px !important;
  }
}
