@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: PSFournier;
  src: url("https://cdn.justhome.com/cdn/assets/PSFournierPro-Italic.woff2")
    format("woff2");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: SuisseIntl;
  src: url("https://cdn.justhome.com/cdn/assets/SuisseIntl-Ultralight-WebS.woff2")
    format("woff2");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: SuisseIntl;
  src: url("https://cdn.justhome.com/cdn/assets/SuisseIntl-Thin-WebS.woff2")
    format("woff2");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: SuisseIntl;
  src: url("https://cdn.justhome.com/cdn/assets/SuisseIntl-Light-WebS.woff2")
    format("woff2");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: SuisseIntl;
  src: url("https://cdn.justhome.com/cdn/assets/SuisseIntl-Regular-WebS.woff2")
    format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: SuisseIntl;
  src: url("https://cdn.justhome.com/cdn/assets/SuisseIntl-Book-WebS.woff2")
    format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: SuisseIntl;
  src: url("https://cdn.justhome.com/cdn/assets/SuisseIntl-Medium-WebS.woff2")
    format("woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: SuisseIntl;
  src: url("https://cdn.justhome.com/cdn/assets/SuisseIntl-SemiBold-WebS.woff2")
    format("woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: SuisseIntl;
  src: url("https://cdn.justhome.com/cdn/assets/SuisseIntl-Bold-WebS.woff2")
    format("woff2");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: SuisseIntl;
  src: url("https://cdn.justhome.com/cdn/assets/SuisseIntl-Black-WebS.woff2")
    format("woff2");
  font-weight: 900;
  font-display: swap;
}

@layer base {
  :root {
    --color-primary-25: 244 255 253;
    --color-primary-50: 233 254 251;
    --color-primary-800: 26 152 131;
    --color-primary-825: 19 132 113;
    --color-primary-850: 9 90 77;
    --color-primary-875: 9 90 77;
    --color-primary-925: 3 51 43;
    --color-neutral-0: 255 255 255;
    --color-neutral-12: 244 247 251;
    --color-neutral-25: 233 239 247;
    --color-neutral-50: 218 226 239;
    --color-neutral-100: 189 200 221;
    --color-neutral-150: 161 175 203;
    --color-neutral-425: 48 60 107;
    --color-neutral-575: 15 23 68;
    --color-neutral-675: 2 7 50;
    --color-neutral-850: 1 3 16;
    --color-neutral-875: 1 2 13;
    --color-warning: 249 232 108;
    --color-error-50: 255 231 231;
    --color-error-200: 252 159 159;
    --color-error-625: 224 29 29;
    --color-error-700: 205 18 18;
    --color-error-750: 185 12 12;
    --color-error-775: 170 9 9;
    --color-error-825: 133 5 5;
    --color-success-625: 10 150 10;
    --color-success-200: 10 150 10;
    --color-success-50: 231 242 255;
    --color-info-50: 231 242 255;
    --color-info-700: 25 116 219;
    --color-cadet-25: 234 242 255;
    --color-cadet-850: 30 63 113;
    --color-cadet-900: 18 44 82;
    --color-marigold-25: 251 249 245;
    --color-marigold-50: 251 245 233;
    --color-marigold-100: 250 236 209;
    --color-marigold-250: 248 212 140;
    --color-marigold-325: 248 208 130;
    --color-marigold-525: 242 187 77;
    --color-marigold-750: 184 127 13;
    --color-rose-25: 250 237 241;
    --color-rose-425: 199 55 94;
    --color-rose-675: 187 16 62;
    --color-rose-750: 165 8 50;
    --color-mindaro-50: 248 255 232;
    --color-mindaro-325: 210 255 114;
    --color-mindaro-575: 173 235 41;
    --color-mindaro-675: 155 216 23;
    --color-mindaro-850: 101 140 15;
    --color-purple-25: 248 246 255;
    --color-purple-50: 236 231 255;
    --color-purple-375: 159 129 255;
    --color-purple-725: 107 72 220;
    --color-purple-775: 87 58 179;
    --color-purple-850: 67 45 139;
    --color-purple-875: 58 39 119;
    --color-purple-900: 49 33 100;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
  .text-wrap {
    text-wrap: wrap;
  }
  .text-nowrap {
    text-wrap: nowrap;
  }
}

@layer components {
  .focusable {
    @apply focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-800 focus-visible:ring-offset-2;
  }
  .focusable-inside {
    @apply focus:outline-none;
  }
  .focusable-inside:focus-visible {
    box-shadow: inset 0 0 0 2px rgba(var(--color-primary-800) / 1);
  }
}
