@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @supports not (aspect-ratio: 1 / 1) {
    .aspect-square::before {
      float: left;
      padding-top: 100%;
      content: "";
    }
    .aspect-square::after {
      display: block;
      content: "";
      clear: both;
    }
  }
}

@layer utilities {
  .h-screen-safe {
    height: calc(100vh - env(safe-area-inset-bottom));
    max-height: -webkit-fill-available;
  }
}

.i-icon {
  font-size: 1.5rem;
}

img,
a {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  --moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.alice-carousel__stage {
  display: flex;
}

.alice-carousel__stage-item {
  flex: 1 0 auto;
  height: auto !important;
}

.alice-carousel__dots {
  margin: 0 !important;
}

.text-hyphens {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

div#hubspot-messages-iframe-container {
  @apply z-40 !important;
}
